body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.border-top-color {
  border-top: 2px solid rgb(71, 35, 217);
}
.form-switch {
  padding-left: 3.5em !important;
}
.top-border-dotted {
  border-top:  0.5px dotted #808080;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
 