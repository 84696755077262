.toggle-button {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
}

.drawer {
    position: fixed;
    top: 0;
    right: -500px; /* Off-screen by default */
    width: 500px; /* Default width */
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease;
    z-index: 1000; /* Make sure it appears above other content */
    padding: 2rem;
    padding-top: 3rem;
}

.drawer.open {
    right: 0; /* Slide in */
}

/* Close button styles */
.close-button {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 15px;
}

/* Backdrop styles */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 500; /* Make sure it's below the drawer */
}

/* Table styles */
tr {
    padding: 1rem;
}

th, td {
    padding: .5rem 0;
}

th {
    padding-right: 2rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .drawer {
        width: 100%; /* Full width on smaller screens */
        right: -100%; /* Off-screen by default */
    }
    
    .drawer.open {
        right: 0; /* Slide in */
    }
}
